<template>
	<div>
		<div v-if="address && address.startsWith('fyzsm.idshanxi.com')">
			<div class="loginContainer">
				<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/zsmLoginLogo.png"
					class="fyLogo" />
				<div class="leftBox">
					<el-image src="https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/zsmLoginPic.png"
						fit="contain">
					</el-image>
				</div>
				<div class="rightBox">
					<div class="rightTit">方圆证书码系统登录</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
                      (readonly && loginForm.password) || loginForm.password
                        ? 'password'
                        : 'text'
                    " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="address && address.startsWith('fycpm.idshanxi.com')">
			<div class="loginContainer2">
				<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/zsmLoginLogo.png"
					class="fyLogo" />
				<div class="mainBox">
					<div class="rightTit mainBoxTit">方圆产品追溯系统</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
                      (readonly && loginForm.password) || loginForm.password
                        ? 'password'
                        : 'text'
                    " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<div v-if="address && address.startsWith('fymp.86122m.com')">
			<div class="loginContainer3">
				<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/LOGO.png" class="fyLogo1" />
				<div class="leftBox">

				</div>
				<div class="rightBox">
					<div class="rightTit">基于标识的产品铭牌管理系统</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
			          (readonly && loginForm.password) || loginForm.password
			            ? 'password'
			            : 'text'
			        " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<div v-if="address && address.startsWith('fyxj.86122m.com')">
			<div class="loginContainer4">
				<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/LOGO.png" class="fyLogo1" />
				<div class="leftBox">

				</div>
				<div class="rightBox">
					<div class="rightTit">基于标识的巡检系统</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
			          (readonly && loginForm.password) || loginForm.password
			            ? 'password'
			            : 'text'
			        " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<div v-if="address && address.startsWith('fycrk.86122m.com')">
			<div class="loginContainer5">
				<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/LOGO.png" class="fyLogo1" />
				<div class="leftBox">

				</div>
				<div class="rightBox">
					<div class="rightTit">基于标识的产品快速出入库系统</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
			          (readonly && loginForm.password) || loginForm.password
			            ? 'password'
			            : 'text'
			        " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<div v-if="address && address.startsWith('fyfch.86122m.com')">
			<div class="loginContainer6">
				<img src="https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/LOGO.png" class="fyLogo1" />
				<div class="leftBox">

				</div>
				<div class="rightBox">
					<div class="rightTit">基于标识的产品防窜货管理系统</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
			          (readonly && loginForm.password) || loginForm.password
			            ? 'password'
			            : 'text'
			        " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<div v-if="address && address.startsWith('pdm.86122m.com')">
			<div class="loginContainer7">
				<div class="leftBox">

				</div>
				<div class="rightBox">
					<div class="rightTit">涿溪PDM产品数据管理系统</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
			          (readonly && loginForm.password) || loginForm.password
			            ? 'password'
			            : 'text'
			        " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<div v-if="address && address.startsWith('plm.86122m.com')">
			<div class="loginContainer8">
				<div class="leftBox">

				</div>
				<div class="rightBox">
					<div class="rightTit">涿溪PLM产品生命周期管理系统</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
			          (readonly && loginForm.password) || loginForm.password
			            ? 'password'
			            : 'text'
			        " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<div v-if="address && address.startsWith('wms.86122m.com')">
			<div class="loginContainer9">
				<div class="leftBox">

				</div>
				<div class="rightBox">
					<div class="rightTit">涿溪WMS仓储管理系统</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
			          (readonly && loginForm.password) || loginForm.password
			            ? 'password'
			            : 'text'
			        " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<div v-if="address && address.startsWith('crm.86122m.com')">
			<div class="loginContainer10">
				<div class="leftBox">

				</div>
				<div class="rightBox">
					<div class="rightTit">涿溪CRM客户关系管理系统</div>
					<div class="">
						<el-form :model="loginForm" ref="loginRef">
							<el-form-item style="margin-top: 20px" prop="username" id="border-none">
								<div class="fileName sjhItem">手机号</div>
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-autocomplete class="fyLoginInput" type="text" size="large"
									auto-complete="new-accounts" style="width: 300px" v-model="loginForm.username"
									popper-class="showAutocomplete" :fetch-suggestions="getHistory"
									placeholder="请输入手机号"></el-autocomplete>
							</el-form-item>

							<el-form-item style="margin-top: 20px" prop="password" id="border-none">
								<div class="fileName mmItem">密码</div>
								<div class="flex-a-b-c">
									<el-input type="password" style="position: fixed; bottom: -9999px" />
									<el-input :type="
			          (readonly && loginForm.password) || loginForm.password
			            ? 'password'
			            : 'text'
			        " v-model="loginForm.password" size="large" placeholder="请输入密码" show-password
										@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
										class="fyLoginInput" @keyup.enter.native="handleLogin" @click.native="clickEvt"
										ref="password" prefix-icon="el-icon-lock">
									</el-input>
								</div>
							</el-form-item>

							<el-form-item align="center">
								<el-button type="primary" size="large" @click="handleLoginBefore"
									class="fyLoginBtn">登录</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
		<!--原来------开始-->
		<div class="login-wrapper" v-else>
			<div class="bg-wrapper" :class="
      loginType == 3
        ? 'h320'
        : loginType == 2
          ? 'h450'
          : loginType == 3
            ? 'h380'
            : 'w400 h460'
    ">
				<!-- 密码登录 -->
				<div class="user-wrapper" v-if="loginType == 1">
					<div class="switch_img" @click="loginType = 2" :style="{ display: showLogin }" v-if="flag">
						<img src="../../assets/images/login/code_switch.png" class="w164 h52" />
					</div>
					<el-form :model="loginForm" ref="loginRef">
						<el-form-item style="margin-top: 20px" prop="username">
							<p class="cA1 d-block fs8 fwbold bbbule2 pb5" style="float: left">
								账号登录
							</p>
						</el-form-item>
						<el-form-item style="margin-top: 20px" prop="username" id="border-none">
							<el-input type="password" style="position: fixed; bottom: -9999px" />
							<el-autocomplete class="inline-input" type="text" size="large" auto-complete="new-accounts"
								style="width: 300px" v-model="loginForm.username" popper-class="showAutocomplete"
								:fetch-suggestions="getHistory" placeholder="请输入手机号"></el-autocomplete>
						</el-form-item>

						<el-form-item v-if="isShow" style="margin-top: 20px" id="border-none">
							<el-input type="password" style="position: fixed; bottom: -9999px" />
							<el-select v-model="companyName" size="large" filterable remote
								@focus="queryCompany(loginForm.username)" style="width: 300px" placeholder="请选择公司"
								@change="selectCompany">
								<el-option style="width: 300px" v-for="item in oemLogin" :key="item.companyId"
									:label="item.companyName" :value="item.companyId">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item style="margin-top: 20px" prop="password" id="border-none">
							<div class="flex-a-b-c">
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<!--
 :readonly="readonly"

-->
								<el-input :type="
                (readonly && loginForm.password) || loginForm.password
                  ? 'password'
                  : 'text'
              " v-model="loginForm.password" size="large" style="width: 185px; border: none" placeholder="请输入密码"
									show-password @focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
									@keyup.enter.native="handleLogin" @click.native="clickEvt" ref="password"
									prefix-icon="el-icon-lock">
								</el-input>
								<el-button plain type="primary" style="width: 100px" size="large"
									@click="clearInput(loginType = 4)">忘记密码
								</el-button>
							</div>
						</el-form-item>
						<el-form-item style="margin-top: 30px" align="center">
							<el-button plain type="primary" style="width: 48%" size="large"
								@click="clearInput(loginType = 3)" v-if="flag">免费注册体验
							</el-button>
							<el-button type="primary" :class="loginBtnStyle" size="large"
								@click="handleLoginBefore">登录</el-button>
						</el-form-item>
					</el-form>
				</div>
				<!-- 扫码登录 -->
				<div class="code-wrapper" v-if="loginType == 2">
					<div class="switch_img" @click="loginType = 1">
						<img src="../../assets/images/login/phone_switch.png" class="w164 h52" />
					</div>
					<el-form :model="loginForm" :rules="loginRules" align="center" ref="loginRef">
						<iframe :src="codeUrl" class="w300 h400 mt20" scrolling="no" frameborder="0">
						</iframe>
					</el-form>
				</div>

				<!-- 体验登录 -->
				<div class="experience-wrapper" v-if="loginType == 3">
					<div class="switch_img" @click="loginType = 1">
						<img src="../../assets/images/login/phone_switch.png" class="w164 h52" />
					</div>
					<el-form :model="registerForm" :rules="loginRules" ref="loginRef">
						<el-form-item style="margin-top: 20px" prop="username">
							<p class="cA1 d-block fs8 fwbold bbbule2 pb5" style="float: left">
								注册体验
							</p>
						</el-form-item>
						<el-form-item style="margin-top: 20px" prop="username" id="border-none">
							<el-input type="password" style="position: fixed; bottom: -9999px" />
							<el-input v-model="registerForm.username" size="large" style="width: 300px; border: none"
								placeholder="请输入手机号" prefix-icon="el-icon-mobile"></el-input>
						</el-form-item>
						<el-form-item style="margin-top: 20px" prop="password" id="border-none">
							<div class="flex-a-b-c">
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-input v-model="registerForm.password" auto-complete="new-accounts" size="large"
									style="width: 160px; border: none" placeholder="请输入验证码"
									prefix-icon="el-icon-lock"></el-input>
								<el-button plain type="primary" :disabled="countDown !== 0 ? true : false" size="large"
									@click="getSendCode(registerForm.username)">{{
                    countDown ? `重新发送(${countDown})` : "获取验证码"
                }}</el-button>
							</div>
						</el-form-item>

						<el-form-item style="margin-top: 30px">
							<el-button plain type="primary" style="width: 48%" size="large"
								@click="clearInput(loginType = 1)">已有账号，去登录
							</el-button>
							<el-button type="primary" style="width: 48%" size="large"
								@click="handleLogin('test')">立即体验</el-button>
						</el-form-item>
					</el-form>
				</div>
				<!-- 忘记密码 -->
				<div class="reset-wrapper" v-if="loginType == 4">
					<div class="switch_img" @click="loginType = 1">
						<img src="../../assets/images/login/phone_switch.png" class="w164 h52" />
					</div>
					<el-form :model="resetForm" ref="loginRef">
						<el-form-item style="margin-top: 20px">
							<p class="cA1 d-block fs8 fwbold bbbule2 pb5" style="float: left">
								忘记密码
							</p>
						</el-form-item>

						<el-form-item style="margin-top: 20px">
							<div class="flex-a-c">
								<div class="flex-a-c" v-for="(item, index) in resetState" :key="index">
									<div class="w24 h24 br50p be6eb flex-c-c" :class="
                  resetType == item.type
                    ? 'bgbule cfff'
                    : resetType > item.type
                      ? 'cA2 bgfff'
                      : ''
                " v-if="resetType <= item.type">
										{{ index + 1 }}
									</div>
									<i class="el-icon-circle-check fs12 cblue" v-else></i>
									<p class="fs7 ml10 cA2" :class="resetType == item.type ? 'cA1 fwbold' : ''">
										{{ item.title }}
									</p>
									<p class="mr10 ml10" v-if="index !== 2">-</p>
								</div>
							</div>
						</el-form-item>

						<el-form-item style="margin-top: 20px" id="border-none" v-if="resetType == 1">
							<div class="mb10">
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-input v-model="resetForm.username" auto-complete="new-accounts" size="large"
									style="border: none" placeholder="请输入手机号" prefix-icon="el-icon-mobile"></el-input>
							</div>
							<!-- <div class="mb10">
              <el-input
                type="password"
                style="position: fixed; bottom: -9999px"
              />
              <el-select
                v-model="companyName"
                auto-complete="new-accounts"
                size="large"
                filterable
                remote
                @focus="queryCompany(resetForm.username)"
                style="width: 100%"
                placeholder="请选择公司"
                @change="selectCompany"
              >
                <el-option
                  style="width: 300px"
                  v-for="item in oemLogin"
                  :key="item.companyId"
                  :label="item.companyName"
                  :value="item.companyId"
                >
                </el-option>
              </el-select>
            </div> -->
							<div class="flex-a-b-c">
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-input v-model="resetForm.imgCode" auto-complete="new-accounts" size="large"
									style="border: none; margin-right: 10px" placeholder="图形验证码"
									prefix-icon="el-icon-lock"></el-input>
								<img :src="imgCode" v-if="imgCode" @click="showResetUserInfo" class="w100 h40" />
								<div class="bgf7fa w100 h40" v-else></div>
								<!-- <el-button plain type="primary" style="width: 80px" size="large" @click="getSendCode">验证码</el-button> -->
							</div>
							<div class="flex-a-b-c mt10">
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-input v-model="resetForm.messageCode" auto-complete="new-accounts" size="large"
									style="border: none; margin-right: 10px" placeholder="短信验证码"
									prefix-icon="el-icon-lock"></el-input>
								<el-button plain type="primary" :disabled="resetCountDown !== 0 ? true : false"
									size="large" @click="getResetSendCode(resetForm.username)">{{
                    resetCountDown ? `重新发送(${resetCountDown})` : "获取验证码"
                }}</el-button>
							</div>
						</el-form-item>
						<el-form-item style="margin-top: 20px" id="border-none" v-if="resetType == 2">
							<div class="flex-a-b-c mb20">
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-input :type="
                (readonly && resetPwdForm.password) || resetPwdForm.password
                  ? 'password'
                  : 'text'
              " :readonly="readonly" v-model="resetPwdForm.password" auto-complete="new-accounts" size="large"
									style="border: none" placeholder="请输入新密码" prefix-icon="el-icon-mobile"
									@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
									@click.native="clickEvt" ref="password"></el-input>
							</div>
							<div class="flex-a-b-c">
								<el-input type="password" style="position: fixed; bottom: -9999px" />
								<el-input :type="
                (readonly && resetPwdForm.secondPassword) || resetPwdForm.secondPassword
                  ? 'password'
                  : 'text'
              " :readonly="readonly" v-model="resetPwdForm.secondPassword" auto-complete="new-accounts" size="large"
									style="border: none" placeholder="请重复新密码" prefix-icon="el-icon-lock"
									@focus="changAttr($event, 'focus')" @blur="changAttr($event, 'blur')"
									@click.native="clickEvt"></el-input>
							</div>
						</el-form-item>

						<el-form-item style="margin-top: 20px" id="border-none" v-if="resetType == 3">
							<div class="flex-c-c h80 flex-column">
								<i class="el-icon-circle-check fs24 cblue"></i>
								<p class="cA1 fs14">设置成功</p>
							</div>
						</el-form-item>
						<el-form-item style="margin-top: 30px" v-if="resetType == 1">
							<el-button type="primary" style="width: 100%" size="large"
								@click="checkNumber">下一步</el-button>
							<div class="flex-a-b-c mt10 cblue">
								<p @click="clearInput(loginType = 3, resetType = 1)" class="hover_pointer">
									免费注册体验
								</p>
								<p @click="clearInput(loginType = 1, resetType = 1)" class="hover_pointer">
									返回登录>
								</p>
							</div>
						</el-form-item>
						<el-form-item style="margin-top: 30px" v-else-if="resetType == 2">
							<el-button type="primary" style="width: 100%" size="large"
								@click="changePassword">下一步</el-button>
							<div class="flex-a-b-c mt10 cblue">
								<p @click="clearInput(loginType = 3, resetType = 1)" class="hover_pointer">
									免费注册体验
								</p>
								<p @click="clearInput(loginType = 1, resetType = 1)" class="hover_pointer">
									返回登录>
								</p>
							</div>
						</el-form-item>
						<el-form-item style="margin-top: 30px" v-if="resetType == 3">
							<el-button plain type="primary" style="width: 100%" size="large" @click="clearInput(loginType = 1, resetType = 1)
            ">立即登录</el-button>
							<div class="flex-c-c mt10 cblue">
								<p @click="clearInput(loginType = 3, resetType = 1)" class="hover_pointer">
									免费注册体验
								</p>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<!--原来----------结束-->

	</div>
</template>

<script>
	import api from "@/api/login";
	import {
		getDataTimeSec
	} from "@/utils/index";
	import {
		loginConfig
	} from "@/utils/config";
	import {
		fetchMenuList
	} from '@/api/login'
	import {
		queryOemDomain
	} from "@/api/oemConfig.js";
	import {
		get
	} from "@/utils/request";
	import store from "@/store";


	export default {
		name: "login",

		data() {
			return {
				loginForm: {
					username: "", // 19834023193
					password: "",
				},
				isShow: false,
				showLogin: "none",
				registerForm: {
					username: "",
					password: "",
					companyName: "",
					companyId: "",
				},
				resetForm: {
					username: "",
					imgCode: "",
					messageCode: "",
				},
				resetPwdForm: {
					password: "",
					secondPassword: "",
				},
				companyName: "", // 公司名
				oemLogin: [],
				loginType: 1, //登录方式 1为账号密码登录  2为 扫码登录  3为 体验账号登录 4为 忘记密码
				companyId: "",
				codeUrl: "",
				resetType: 1, //修改密码方式 1为手机号验证  2为 设置新密码  3为 成功
				imgCode: "",
				signature: "", //认证签名
				resetState: [{
						title: "安全验证",
						type: 1,
					},
					{
						title: "设置新密码",
						type: 2,
					},
					{
						title: "设置成功",
						type: 3,
					},
				],
				countDown: 0,
				resetCountDown: 0,
				readonly: true,
				loginRules: [],
				flag: true,
				loginBtnStyle: 'loginBtn1',
				address: '', // 访问域名
			};
		},
		watch: {
			'loginForm.username': {
				handler: function() {
					if (this.loginForm.username.length == 11) {
						this.queryCompany(this.loginForm.username)
					}
				},
				deep: true
			}
		},
		async created() {
			this.address = window.location.host;
			// console.log("domain:", document.domain);
			this.getCodeUrl();
			this.getUserInfo();
			this.showResetUserInfo();
			var address = window.location.host;
			var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
			console.log('当前域名----', address)
			if (address.startsWith("scrm.86122m.com") || address.startsWith(
					"localhost")) {
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
				document.getElementsByTagName('head')[0].appendChild(link);
				//获取网页标题
				document.title = "涿溪一码通";
				this.showLogin = "disabled";
			} else if (address.startsWith("pdm.86122m.com")) {
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
				document.getElementsByTagName('head')[0].appendChild(link);
				//获取网页标题
				document.title = "涿溪PDM产品数据管理系统";
			} else if (address.startsWith("plm.86122m.com")) {
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
				document.getElementsByTagName('head')[0].appendChild(link);
				//获取网页标题
				document.title = "涿溪PLM产品生命周期管理系统";
			} else if (address.startsWith("wms.86122m.com")) {
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
				document.getElementsByTagName('head')[0].appendChild(link);
				//获取网页标题
				document.title = "涿溪WMS仓储管理系统";
			} else if (address.startsWith("crm.86122m.com")) {
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/zxNemLogo.ico";
				document.getElementsByTagName('head')[0].appendChild(link);
				//获取网页标题
				document.title = "涿溪CRM客户关系管理系统";
			} else if (address.startsWith("fyzsm.idshanxi.com")) { // fyzsm.cqm.cn
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico";
				document.getElementsByTagName('head')[0].appendChild(link);
				//获取网页标题
				document.title = "方圆证书码系统";
			} else if (address.startsWith("fycpm.idshanxi.com")) { // fycpm.cqm.cn
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico";
				document.getElementsByTagName('head')[0].appendChild(link);
				document.title = "方圆产品追溯系统";
			} else if (address.startsWith("fymp.86122m.com")) { // fycpm.cqm.cn
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico"
				document.getElementsByTagName('head')[0].appendChild(link)
				document.title = "基于标识的产品铭牌管理系统"
			} else if (address.startsWith("fyxj.86122m.com")) { // fycpm.cqm.cn
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico"
				document.getElementsByTagName('head')[0].appendChild(link)
				document.title = "基于标识的巡检系统"
			} else if (address.startsWith("fycrk.86122m.com")) { // fycpm.cqm.cn
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico"
				document.getElementsByTagName('head')[0].appendChild(link)
				document.title = "基于标识的产品快速出入库系统"
			} else if (address.startsWith("fyfch.86122m.com")) { // fycpm.cqm.cn
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/fyIcon.ico"
				document.getElementsByTagName('head')[0].appendChild(link)
				document.title = "基于标识的产品防窜货管理系统"
			} else {
				link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/gbylogo.ico";
				document.getElementsByTagName('head')[0].appendChild(link);
				//获取网页标题
				document.title = "广州标准化研究院";
			}

			// if (document.domain != "localhost" && document.domain != "crm.86122m.com" && document.domain !== "scrm.86122m.com") {
			//   this.flag = false
			//   this.loginBtnStyle='loginBtn'
			//   this.showLogin = "none";
			//   const oemConfig = await queryOemDomain({ oemDomain: document.domain });
			//   if (!oemConfig) {
			//     this.$message.error('oem配置错误,请联系售后');
			//     return;
			//   }
			//   var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
			//   link.type = 'image/x-icon';
			//   link.rel = 'shortcut icon';
			//   // if (oemConfig) {
			//   //   link.href = oemConfig.data.iconLogo;
			//   //   document.getElementsByTagName('head')[0].appendChild(link);
			//   //   //获取网页标题
			//   //   document.title = oemConfig.data.oemName;
			//   // } else {
			//   //
			//   //
			//   // }
			// } else {
			//   this.showLogin = "disabled";
			//   var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
			//   link.type = 'image/x-icon';
			//   link.rel = 'shortcut icon';
			//   var address = window.location.host;
			//   if (address.startsWith("crm.86122m.com")||address.startsWith("scrm.86122m.com")||address.startsWith("localhost")){
			//     link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/favicon.ico";
			//     document.getElementsByTagName('head')[0].appendChild(link);
			//     //获取网页标题
			//     document.title = "码客通后台";
			//   }else {
			//     link.href = "https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/icon/gbylogo.ico";
			//     document.getElementsByTagName('head')[0].appendChild(link);
			//     //获取网页标题
			//     document.title = "广州标准化研究院";
			//   }
			// }

		},
		mounted() {},

		methods: {
			trim(str) {
				return str.replace(/^\s+|\s+$/g, '');
			},
			//密码的历史记录
			getHistory(queryString, cb) {
				let $cname = document.querySelector(".showAutocomplete");
				let history = JSON.parse(localStorage.getItem("password")),
					arr = [];
				if (history) {
					history.forEach((v, i) => {
						arr.push({
							value: v.value.username,
							password: v.value.password,
						});
					});
				}
				console.log($cname)
				arr.length == 0 ? ($cname.style.display = "none") : ''
				cb(arr);
			},
			// 查询公司
			queryCompany(val, isLogin) {
				if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(val)) {
					this.loginForm.username = "";
					this.resetForm.username = "";
					this.$message({
						title: "警告",
						message: "请输入正确的电话号码",
						type: "warning",
					});
					return;
				}
				api.fetchCompany({
					phone: val
				}, {
					showLoading: false
				}).then((res) => {
					// this.oemLogin = [{ companyId: 87, companyName: '测试企业' }, { companyId: 89, companyName: '测试企业123' }]
					this.oemLogin = res.data;

					if (this.oemLogin && this.oemLogin.length) {

						if (this.oemLogin.length > 1) {
							this.isShow = true
						} else {
							this.companyName = this.oemLogin[0].companyName
							this.companyId = this.oemLogin[0].companyId

						}
						// if (isLogin) {
						//   this.handleLogin()
						// }
					} else {
						this.$message({
							title: "警告",
							message: "暂无公司",
							type: "warning",
						});
					}
				});
			},
			getSendCode(phone) {
				if (this.countDown) return;
				if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(phone)) {
					phone = "";
					this.$message({
						title: "警告",
						message: "请输入正确的电话号码",
						type: "warning",
					});
					return;
				}
				api.fetchCompany({
					phone: phone
				}, {
					showLoading: false
				}).then((res) => {
					this.countDown = 60;
					this.timer = setInterval(() => {
						this.countDown--;
						if (this.countDown === 0) {
							clearInterval(this.timer);
						}
					}, 1 * 1000);
					console.log(res);
					this.registerForm.companyId = res.data[0].companyId;
					this.registerForm.companyName = res.data[0].companyName;
				});
				api
					.fetchVeriCode({
						phone: phone,
						type: 1
					}, {
						showLoading: false
					})
					.then((res) => {})
					.catch((err) => {
						console.log(err);
					});
			},

			getResetSendCode(phone) {
				if (this.resetCountDown) return;
				if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(phone)) {
					phone = "";
					this.$message({
						title: "警告",
						message: "请输入正确的电话号码",
						type: "warning",
					});
					return;
				}
				api.fetchCompany({
					phone: phone
				}, {
					showLoading: false
				}).then((res) => {
					this.resetCountDown = 60;
					this.timer = setInterval(() => {
						this.resetCountDown--;
						if (this.resetCountDown === 0) {
							clearInterval(this.timer);
						}
					}, 1 * 1000);
					console.log(res);
					this.registerForm.companyId = res.data[0].companyId;
					this.registerForm.companyName = res.data[0].companyName;
				});
				api
					.fetchVeriCode({
						phone: phone,
						type: 1
					}, {
						showLoading: false
					})
					.then((res) => {})
					.catch((err) => {
						console.log(err);
					});
			},

			getUserInfo() {
				// let data = {
				//   code:"091LE0100dV9sL1JAp100a9sAR3LE01i",
				//   state:'one-stand'
				// }
				// api.pcLoginCallBack(data)
				// .then(res=>{
				//   console.log(res)
				// })
			},
			//获取验证码
			getCodeUrl() {
				var host = "";
				var appId = "";
				console.log('当前', window.location.host)
				if (window.location.host === loginConfig.crmHost) {
					host = loginConfig.crmHost;
					appId = loginConfig.crmAppId
				} else {
					host = loginConfig.scrmHost
					appId = loginConfig.scrmAppId
				}
				this.codeUrl = "https://open.weixin.qq.com/connect/qrconnect?appid=" + appId + "&redirect_uri=http://" +
					host + "/login&response_type=code&scope=snsapi_login&state=" + appId
			},
			// 选择公司
			selectCompany(val) {
				this.companyId = val;
			},
			//获取图形验证码
			showResetUserInfo() {
				let data = {
					phone: this.resetForm.username || "",
				};
				api
					.getVerifiCode(data)
					.then((res) => {
						console.log();
						this.imgCode = "data:image/png;base64," + res.data;
					})
					.catch((err) => {
						console.log(err);
					});
			},
			checkNumber() {
				console.log('----------------------修改密码515')
				this.queryCompany(this.resetForm.username, false)
				// if (this.oemLogin[0].companyId == 1) {
				//   this.$message.error('此手机号未注册，请输入已注册手机号或点击免费注册体验可免费体验查看');
				//   return
				// }
				if (
					!this.resetForm.username ||
					!this.resetForm.imgCode ||
					!this.resetForm.messageCode
				) {
					this.$message({
						message: "手机号或验证码为空",
						type: "error",
					});
					return;
				}
				if (!/^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[1-9])[0-9]{8}$/.test(this.resetForm
						.username)) {
					this.loginForm.username = "";
					this.$message({
						title: "警告",
						message: "请输入正确的电话号码",
						type: "warning",
					});
					return;
				}
				let data = {
					imgCode: this.resetForm.imgCode,
					smsCode: this.resetForm.messageCode,
					phone: this.resetForm.username,
				};
				api
					.forgetPwd(data)
					.then((res) => {
						this.showResetUserInfo();
						this.signature = res.data;
						this.resetType = 2;
					})
					.catch((err) => {
						if (err.code == 201) {
							this.$message({
								title: "警告",
								message: err.message,
								type: "warning",
							});
						}
					});
			},
			changePassword() {
				console.log('--------------修改密码')
				if (
					this.resetPwdForm.password.length < 6 ||
					this.resetPwdForm.secondPassword.length < 6
				) {
					this.$message({
						message: "密码长度不得小于六位",
						type: "warning",
					});
					return;
				}
				if (this.resetPwdForm.password !== this.resetPwdForm.secondPassword) {
					this.$message({
						message: "两次输入的密码不一，请重新输入",
						type: "warning",
					});
					return;
				}
				let data = {
					signature: this.signature,
					password: this.resetPwdForm.password,
					companyId: this.companyId,
				};
				api
					.updatePwd(data)
					.then((res) => {
						console.log(res);
						this.resetType = 3;
					})
					.catch((err) => {
						if (err.code == 201) {
							this.$message({
								message: err.message,
								type: "warning",
							});
							return;
						}
					});
			},
			// 清除登录数据
			clearInput() {
				this.loginForm = {
					username: "",
					password: "",
				}
				this.registerForm = {
					username: "",
					password: "",
					companyName: "",
					companyId: "",
				}
				this.resetForm = {
					username: "",
					imgCode: "",
					messageCode: "",
				}
				this.resetPwdForm = {
					password: "",
					secondPassword: "",
				}
				this.companyName = ""
			},
			// 登录前查是否有公司
			handleLoginBefore() {
				// this.queryCompany(this.loginForm.username, true)
				store.dispatch('setHasRole', false)
				this.handleLogin()
			},
			// 登录请求
			handleLogin(type) {
				console.log(location.protocol + '//' + location.host);
				//登录时间
				let date = new Date();
				date = getDataTimeSec(date);
				let value = "";
				if (this.oemLogin.length > 1) {
					this.oemLogin.map(item => {
						if (item.companyId == this.companyId) {
							return this.companyName = item.companyName
						}

					})
				}
				if (type !== "test") {
					//非体验用户登录
					value = {
						username: this.loginForm.username.trim(),
						phone: this.loginForm.username.trim(),
						password: this.loginForm.password.trim(),
						companyName: this.companyName,
						companyId: this.companyId,
					};
				} else {
					//体验用户登录
					value = {
						username: this.registerForm.username.trim(),
						phone: this.registerForm.username.trim(),
						// password: this.loginForm.password,
						smsCode: this.registerForm.password,
						companyName: this.registerForm.companyName,
						companyId: this.registerForm.companyId,
					};
				}

				let than = this,
					passwordArr = [], //
					text = "password";

				localStorage.removeItem("password");
				passwordArr.push({
					value
				});

				if (JSON.parse(localStorage.getItem(text))) {
					// 判断是否已有xxx查询记录的localStorage
					if (localStorage.getItem(text).indexOf(value.username) > -1) {
						// 判断是否已有此条查询记录，若已存在，则修改顺序
						let userArr = JSON.parse(localStorage.getItem(text));
						console.log(userArr);
						userArr.forEach((v, k) => {
							console.log(v);
							if (v.value.username == value.username) {
								//将重复的username顺序提前
								let currentUser = userArr.splice(k, 1);
								userArr.unshift(currentUser[0]);
								localStorage.setItem(text, JSON.stringify(userArr));
							}
						});
					} else if (JSON.parse(localStorage.getItem(text)).length >= 5) {
						//记录大于五条时删除最后一条添加新的记录
						let arr = JSON.parse(localStorage.getItem(text));
						arr.pop();
						arr.unshift(passwordArr[0]);
						localStorage.setItem(text, JSON.stringify(arr));
					} else {
						//没有记录且不大于五条记录
						localStorage.setItem(
							text,
							JSON.stringify(
								passwordArr.concat(JSON.parse(localStorage.getItem(text)))
							)
						);
					}
				} else {
					// 首次创建
					localStorage.removeItem("password");
					localStorage.setItem(text, JSON.stringify(passwordArr));
				}
				if (type !== "test") {
					//手机密码登录
					if (value.companyId == 1) {
						this.$message.error('此手机号未注册，请输入已注册手机号或点击免费注册体验可免费体验查看');
						return
					}
					if (!value.username || !value.password) {
						this.$message.error('账号信息不完整，请填写完整');
						return
					}
					console.log('1111111111', value)
					api
						.fetchLoginByPhone(value)
						.then((res) => {
							if (res.code === 200) {
								localStorage.removeItem('isSourceAdmin')
								//存储登录用户信息
								this.$store.state.loginRoot.loginDate = res.data.lastLoginTime
								this.$store.state.loginRoot.userInfo = res.data;
								localStorage.setItem("info", JSON.stringify(res.data));
								localStorage.setItem("loginDate", res.data.lastLoginTime);
								localStorage.setItem("isScrmPhone", res.data.isScrmPhone);
								//存储token
								localStorage.setItem("token", res.data.token);

								// todo
								fetchMenuList().then(res => {
									let path = ''
									if (res.data && res.data.length) {

										if (res.data[0].children && res.data[0].children.length) {
											path = res.data[0].children[0].path
										} else {
											path = res.data[0].path
										}

										this.$router.push(path);
									} else {
										this.$router.push("/dashboard");
									}
								})
								// this.$router.push("/dashboard");

							} else {
								this.$message.error(res.data);
								return;
							}
						})
						.catch((err) => {
							this.$message.error(err.message);
						});
				} else {

					//体验账号登录
					if (!value.username || !value.smsCode) {
						this.$message.error('账号信息不完整，请填写完整');
						return
					}
					api.fetchLogin(value)
						.then((res) => {
							if (res.code === 200) {
								localStorage.removeItem('isSourceAdmin')
								localStorage.removeItem('popped')
								//存储登录用户信息
								this.$store.state.loginRoot.loginDate = date;
								this.$store.state.loginRoot.userInfo = res.data;
								localStorage.setItem("info", JSON.stringify(res.data));
								localStorage.setItem("loginDate", res.data.lastLoginTime)
								//存储token
								localStorage.setItem("token", res.data.token);
								// todo 
								fetchMenuList().then(res => {
									let path = ''
									if (res.data && res.data.length) {
										if (res.data[0].children && res.data[0].children.length) {
											path = res.data[0].children[0].path
										} else {
											path = res.data[0].path
										}
										this.$router.push(path);
									} else {
										this.$router.push("/dashboard");
									}
								})

							} else {
								this.$message.error(res.data);
								return;
							}
						})
						.catch((err) => {
							this.$message.error(err.message);
						});
				}
			},

			changAttr(e, type) {
				if (type === "focus") {
					if (e) {
						e.stopPropagation();
						e.preventDefault();
					}
					setTimeout(() => {
						this.readonly = false;
					}, 100);
				} else {
					if (e) {
						e.stopPropagation();
					}
					this.readonly = true;
				}
			},
			clickEvt() {
				if (this.$refs.password) {
					this.$refs.password.$refs.input.onmousedown = (evt) => {
						if (evt) {
							evt.preventDefault();
							evt.stopPropagation();
						}
						if (this.loginForm.password === "" || this.readonly) {
							this.$refs.password.$refs.input.blur();
							setTimeout(() => {
								this.$refs.password.$refs.input.focus();
							}, 0);
						}
						return false;
					};
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.login-wrapper {
		position: relative;
		width: 100%;
		height: 100vh;
		background-image: url("../../assets/images/login/login_bg.png");
		background-size: cover;
		display: flex;
		flex-direction: row;
		z-index: 1;
		justify-content: center;
		align-items: center;
	}

	.bg-wrapper {
		width: 380px;
		z-index: 9999;
		background: #ffffff;
		border: 1px solid #ffffff;
		box-shadow: 0px 0px 20px rgba(81, 205, 203, 0.08);
		opacity: 1;
		border-radius: 10px;
		display: flex;
		flex-direction: row;
		transition: all 0.4s;
		position: relative;
	}

	.switch_img {
		position: absolute;
		top: 8px;
		right: 8px;
	}

	.bg-wrapper:hover {
		box-shadow: 0px 0px 40px rgba(39, 148, 175, 0.1);
		transition: all 0.4s;
	}

	.bg-wrapper .el-input__inner {
		border: none;
	}

	.user-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		padding: 40px 0;
	}

	.opacity0 {
		opacity: 1;
		transition: all 0.2s;
	}

	.opacity1 {
		opacity: 1;
		transition: all 0.2s;
	}

	.code-wrapper {
		width: 100%;
		height: 100%;
		padding: 30px 0;
	}

	.experience-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		padding: 40px 0;
	}

	.reset-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		padding: 40px 0;
	}

	.loginBtn {
		width: 100%;
	}

	.loginBtn1 {
		width: 48%;
	}


	// 方圆
	.loginContainer {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/zsmLoginBg.png') top center no-repeat;
		background-size: 100% 100%;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
	}

	.fyLogo1 {
		width: 152px;
		height: 94px;
		position: absolute;
		top: 67px;
		left: 75px;
	}

	.fyLogo {
		width: 131px;
		height: 60px;
		position: absolute;
		top: 67px;
		left: 75px;
	}

	.leftBox {
		width: 778px;
		height: 793px;
		// border: 1px solid #f00;
	}

	.rightBox {
		// border: 1px solid #f00;
		top: 200px;
		width: 480px;
		height: 620px;
		background: #FFFFFF;
		box-shadow: 2px 6px 18px 0px #0F2B73;
		border-radius: 20px;
		padding: 44px;
		box-sizing: border-box;
	}

	.rightTit {
		font-family: Source Han Sans CN;
		font-weight: bold;
		font-size: 26px;
		color: #22201E;
		border-bottom: 1px solid #EAF1F8;
		padding-bottom: 20px;
		margin-bottom: 60px;
	}

	.fyLoginBtn {
		width: 388px;
		height: 60px;
		background: #0C2975;
		border-radius: 30px;
		text-align: center;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 20px;
		margin-top: 60px;
	}

	.fyLoginInput {
		width: 388px !important;
		height: 60px;
		border-radius: 30px;
		border: 1px solid #D5DFEB;
		box-sizing: border-box;
		padding: 0 10px;
	}

	.fyLoginInput::v-deep .el-input input {
		border: none;
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 18px;
		margin-top: 15px;
	}

	.fyLoginInput::v-deep .el-input__inner {
		// border: 1px solid #f00;
		margin-top: 15px;
		border: none;
	}

	.fyLoginInput::v-deep .el-input__icon {
		line-height: 65px;
	}

	.fileName {
		font-family: Source Han Sans CN;
		font-weight: 400;
		font-size: 18px;
		color: #22201E;
		padding-bottom: 13px;
	}

	.mmItem {
		padding-top: 30px;
	}

	.loginContainer2 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/cpmLoginBg.png') top center no-repeat;
		background-size: 100% 100%;
	}

	.loginContainer3 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/fympLoginBg.png') top center no-repeat;
		background-size: 100% 100%;
	}

	.loginContainer4 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/fyxjLoginBg.png') top center no-repeat;
		background-size: 100% 100%;
	}

	.loginContainer5 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/crkLoginBg.png') top center no-repeat;
		background-size: 100% 100%;
	}

	.loginContainer6 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/fchLoginBg.png') top center no-repeat;
		background-size: 100% 100%;
	}

	.loginContainer7 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/sjgl.png') top center no-repeat;
		background-size: 100% 100%;
	}

	.loginContainer8 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/smzqgl.png') top center no-repeat;
		background-size: 100% 100%;
	}

	.loginContainer9 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/ccgl.png') top center no-repeat;
		background-size: 100% 100%;
	}

	.loginContainer10 {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		padding-top: 5%;
		display: flex;
		align-items: flex-start;
		justify-content: space-around;
		background: url('https://mzmpic.oss-cn-beijing.aliyuncs.com/one-stand/login/fy/khgxgl.png') top center no-repeat;
		background-size: 100% 100%;
	}

	.mainBox {
		width: 480px;
		height: 620px;
		background: #FFFFFF;
		box-shadow: 2px 6px 18px 0px #0F2B73;
		border-radius: 20px;
		padding: 44px;
		box-sizing: border-box;
	}

	.mainBoxTit {
		text-align: center;
	}
</style>